import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BaseForm,
  ITablePageable,
  ModuleKeywords,
  RequestHandlerOptions,
  Task,
  TypesWithName,
  getEnumOptions,
} from '@shared/classes';
import { RelationsService } from '@shared/services/relation-service/relations.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { takeUntil } from 'rxjs';
import { TaskDataService } from '../../services/data/task-data.service';

@Component({
  selector: 'app-task-item-form',
  templateUrl: './task-item-form.component.html',
  styleUrls: ['./task-item-form.component.scss'],
})
export class TaskItemFormComponent extends BaseForm<Task> implements OnInit {
  listOfPriority = getEnumOptions(Task.PriorityEnum);
  listOfStatus = getEnumOptions(Task.StatusEnum);

  listOfTypes = getEnumOptions(TypesWithName);

  selectedType;
  isLoading = false;
  items: any[] = [];

  @Input()
  typesMap: any;

  relationType = 'TASK';
  @Input()
  itemId: string;

  @Input()
  searchT: string = null;

  @Input()
  editMode: Boolean = false;

  pageInfo: ITablePageable = new ITablePageable();

  apiOptions: RequestHandlerOptions = {
    showLoading: true,
    showMsg: true,
  };
  constructor(
    private requestService: TaskDataService,
    private relationsService: RelationsService,

    public viewModeService: ViewModeService
  ) {
    super(viewModeService, ModuleKeywords.Task);
  }

  ngOnInit(): void {
    this.formGroup?.controls?.relatedToType?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.selectedType = res ?? null;

      this.formGroup?.patchValue({
        relatedToCode: null,
      });
    });

    if (this.editMode) {
      this.requestService.getByIdOrCode(this.itemId, this.apiOptions).subscribe((res) => {
        this.setData(res.data);
      });
    } else {
      if (!!this.searchT) {
        this.formGroup.controls.task.patchValue(this.searchT);
      }
    }
  }

  getData() {
    // return this.formGroup.getRawValue();
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      subject: new FormControl(null, Validators.required),
      description: new FormControl(null),
      principle: new FormControl(null, Validators.required),
      sender: new FormControl(null),
      priority: new FormControl(null),
      status: new FormControl(Task.StatusEnum.Todo),
      relatedToType: new FormControl(null),
      relatedToCode: new FormControl(null),
      completed: new FormControl(false),
      progress: new FormControl(0),
      dueDate: new FormControl(null),
    });
  }
}

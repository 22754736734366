<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="'Assignee'"
        [placeholder]="'Search Assignee...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.principle"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-user-selector
        [label]="'Reporter'"
        [placeholder]="'Reporter'"
        [control]="formGroup?.controls?.sender"
        [optionValue]="'userName'"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-basic-input
        label="Subject"
        [placeholder]="'Subject'"
        [control]="formGroup?.controls?.subject"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="'Priority'"
        [placeholder]="'Select Item'"
        [name]="'dropdown'"
        [optionLabel]="'label'"
        [optionValue]="'value'"
        [items]="listOfPriority"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.priority"
        [viewMode]="fieldViewMode"
        [badgeView]="true"
        [appendTo]="'body'"
      ></app-drop-down-input>
    </div>
    @if (editMode) {
      <div class="col-12 md:col-6">
        <app-drop-down-input
          [label]="'Status'"
          [placeholder]="'Select Item'"
          [name]="'dropdown'"
          [optionLabel]="'label'"
          [optionValue]="'value'"
          [items]="listOfStatus"
          [optionValue]="'value'"
          [control]="formGroup?.controls?.status"
          [viewMode]="fieldViewMode"
          [badgeView]="true"
        ></app-drop-down-input>
      </div>
    }

    <div class="col-12 md:col-6">
      <app-drop-down-input
        label="Target "
        [placeholder]="'Select Item'"
        [name]="'dropdown'"
        [optionLabel]="'label'"
        [items]="listOfTypes"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.relatedToType"
        [badgeView]="true"
        [viewMode]="fieldViewMode"
      ></app-drop-down-input>
    </div>

    @if (selectedType) {
      <div class="col-12 md:col-6">
        <app-target-code-selector
          [label]="'Target Element'"
          [placeholder]="'Search Items'"
          [control]="formGroup?.controls?.relatedToCode"
          [viewMode]="fieldViewMode"
          [multi]="false"
          [targetTypes]="[selectedType]"
        >
        </app-target-code-selector>
      </div>
    }

    @if (editMode) {
      <div class="col-12 md:col-6">
        <app-p-slider-input
          [name]="'progress'"
          label="Progress "
          [placeholder]="'Enter Progress Number'"
          [control]="formGroup?.controls?.progress"
          [viewMode]="fieldViewMode"
          [valueSuffix]="'%'"
        ></app-p-slider-input>
      </div>
    }
    <div class="col-12 md:col-6">
      <app-date-input
        [label]="'Due Date'"
        [placeholder]="'2022-11-22'"
        [name]="'dueDate'"
        [showIcon]="true"
        [control]="formGroup?.controls?.dueDate"
        [viewMode]="fieldViewMode"
      ></app-date-input>
    </div>
    <div class="col-12 md:col-12">
      <app-text-editor
        [height]="'100px'"
        [advanced]="true"
        [name]="'textarea'"
        label="Body"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
      ></app-text-editor>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton && showSaveAndClose) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
